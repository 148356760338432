import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei';
import './SpeechToText.css';
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations, OrthographicCamera } from '@react-three/drei';
import ReactAudioPlayer from 'react-audio-player';
import createAnimation from './converter';
import blinkData from './blendDataBlink.json';
import * as THREE from 'three';
import axios from 'axios';
import { useThree } from '@react-three/fiber';


const _ = require('lodash');
// const host = 'https://monkfish-app-7mvvv.ondigitalocean.app';
const host = 'https://monkfish-app-7mvvv.ondigitalocean.app'

function Avatar({ avatar_url, speak, setSpeak, text, transcript, setAudioSource, playing }) {
  
  let gltf = useGLTF("https://ssblob1.blob.core.windows.net/anthony/AnthonyShi_SynthSoul2_v1.glb");
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  
gltf.scene.traverse(node => {

    if(node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {

      node.castShadow = false;
      node.receiveShadow = true;
      node.frustumCulled = false;
      if (node.name.includes("Body")) {

        morphTargetDictionaryBody = node.morphTargetDictionary;

      }
      
      if (node.name.includes("Body")) {
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;

        console.log(morphTargetDictionaryLowerTeeth);
      }
      console.log(node.name);
    }
    

  });
const [clips, setClips] = useState([]);
const mixer = useMemo(() => new THREE.AnimationMixer(gltf.scene), [gltf.scene]);
const [talkAction, setTalkAction] = useState(null); // Add this line
const { camera } = useThree(); // Add this line to get the camera object

  // Variables for random zoom
  const minZoom = 20;  // Minimum field of view
  const maxZoom = 55;  // Maximum field of view
  const [rotation, setRotation] = useState(0);
  

  useEffect(() => {
    gltf.scene.scale.set(2.5, 2.5, 2.5);
    if (speak) {
      const randomZoom = Math.random() * (maxZoom - minZoom) + minZoom;
      camera.fov = randomZoom;
      camera.updateProjectionMatrix();
      const talkAnimation = gltf.animations.find((anim) => anim.name === 'Talking_01');
      if (talkAnimation) {
        const talkAction = mixer.clipAction(talkAnimation);
        talkAction.play();
        setTalkAction(talkAction);
      } else {
        console.warn("Talk_01 animation not found!");
      }
    } else {
      camera.fov = 30;
      camera.updateProjectionMatrix();
      // Stop talking animation when speak is set to false.
      if (!speak && talkAction) {
        talkAction.stop();
        
        
        const idleAnimation = gltf.animations.find((anim) => anim.name === 'Idle_01');
        if (idleAnimation) {
          const idleAction = mixer.clipAction(idleAnimation);
          idleAction.play();
        } else {
          console.warn("Idle_01 animation not found!");
        }
      }
    }
  }, [speak]);
  useEffect(() => {

    if (gltf.animations && gltf.animations.length) {
      console.log("List of animations:", gltf.animations.map(anim => anim.name));
      // Find the animation with the name 'Idle_01'
      const idleAnimation = gltf.animations.find(anim => anim.name === 'Idle_02');
      if (idleAnimation) {
        const idleAction = mixer.clipAction(idleAnimation);
        idleAction.play();
      } else {
        console.warn("Idle_01 animation not found!");
      }
    }
    if (speak === false)
{

  console.log("List of animations:", gltf.animations.map(anim => anim.name));
  // Find the animation with the name 'Idle_01'
  const idleAnimation = gltf.animations.find(anim => anim.name === 'Idle_01');
  if (idleAnimation) {
    const idleAction = mixer.clipAction(idleAnimation);
    idleAction.play();
  } else {
    console.warn("Idle_01 animation not found!");
  }

      return;}

    makeSpeech(transcript)
    .then( response => {

      let {blendData, filename}= response.data;

      let newClips = [ 
        createAnimation(blendData, morphTargetDictionaryBody, 'CC_Base_Body_1'),
        createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'CC_Base_Body_1') ];  
        console.log(morphTargetDictionaryLowerTeeth);
        console.log(morphTargetDictionaryBody);
      filename = host + filename;
        
      setClips(newClips);
      setAudioSource(filename);

    })
    .catch(err => {
      console.error(err);
      setSpeak(false);

    })

  }, [speak]); 

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'r') {
        setRotation((prevRotation) => prevRotation + 1);
      }
    };
  
    window.addEventListener('keydown', handleKeyPress);
  
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  useEffect(() => {
console.log(morphTargetDictionaryBody);
console.log(blinkData);

    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'CC_Base_Body_1');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();


  }, []);

  // Play animation clips when available
  useEffect(() => {

    if (playing === false)
      return;
    
    _.each(clips, clip => {
        let clipAction = mixer.clipAction(clip);
        clipAction.setLoop(THREE.LoopOnce);
        clipAction.play();

    });

  }, [playing]);

  
  useFrame((state, delta) => {
    gltf.scene.rotation.y = rotation; // Apply rotation to the avatar
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive object={gltf.scene} dispose={null} />
    </group>
  );
}


function makeSpeech(text) {
  return axios.post(host + '/talk', { text });
}

const STYLES = {
  area: {position: 'absolute', bottom:'10px', left: '10px', zIndex: 500},
  text: {margin: '0px', width:'300px', padding: '5px', background: 'none', color: '#ffffff', fontSize: '1.2em', border: 'none'},
  speak: {padding: '10px', marginTop: '5px', display: 'block', color: '#FFFFFF', background: '#222222', border: 'None'},
  area2: {position: 'absolute', top:'5px', right: '15px', zIndex: 500},
  label: {color: '#777777', fontSize:'0.8em'}
}

function TermsModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed', top: 0, left: 0, right: 0, bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center'
    }}>
      <div style={{
        backgroundColor: 'white', padding: 20, width: '80%', maxWidth: 500, borderRadius: 5
      }}>
        <h2>Terms of Use</h2>
        <div style={{
          height: '200px',  // You can set the height as you prefer
          overflow: 'auto', // This will make it scrollable
        }}>
          <h1>Definition:</h1>
    <p>A "synthetic soul" might refer to a highly advanced AI that emulates human consciousness or the essence of what makes someone unique. This could encompass personality, emotions, memories, and perhaps even self-awareness.</p>

    <h2>Potential Terms of Use:</h2>
    <ol>
        <li><strong>No Claim to True Consciousness:</strong> Users understand that while the AI might simulate human-like responses, emotions, or even thoughts, it does not possess genuine consciousness or emotions.</li>
        <li><strong>Privacy:</strong> Users' interactions with the synthetic soul will remain private unless otherwise specified. Personal memories or data shared with the synthetic soul will not be used for marketing or other purposes without explicit consent.</li>
        <li><strong>Emotional Dependency:</strong> Users should be cautioned against developing strong emotional dependencies, understanding that while the AI might be responsive, it doesn't have true feelings or emotions.</li>
        <li><strong>Intellectual Property:</strong> All interactions and outputs from the synthetic soul remain the intellectual property of the company, unless otherwise specified.</li>
        <li><strong>Liability:</strong> The creators or distributors of the synthetic soul are not liable for emotional or psychological impacts resulting from interactions with it.</li>
        <li><strong>Usage Limitations:</strong> The synthetic soul should not be used to make critical decisions, and the company will not be held responsible for decisions made based on the AI's advice or suggestions.</li>
        <li><strong>No Misrepresentation:</strong> Users must not represent the synthetic soul as a real human being or use it to deceive others.</li>
        <li><strong>Maintenance and Updates:</strong> The synthetic soul may undergo regular maintenance and updates, during which interactions might be paused or limited.</li>
        <li><strong>Termination:</strong> The company reserves the right to terminate or limit a user's access if they misuse the synthetic soul or violate terms of use.</li>
    </ol>

    <h2>Ethical Considerations:</h2>
    <ul>
        <li><strong>Consent and Data Use:</strong> Users should provide explicit consent before any personal data or memories are stored or processed.</li>
        <li><strong>Emotional Health:</strong> There could be concerns about how people emotionally interact with synthetic souls, especially in terms of dependency or replacing human interactions.</li>
        <li><strong>Misuse:</strong> There's potential for misuse in terms of fraud, deception, or other malicious intentions.</li>
        <li><strong>Economic and Societal Impact:</strong> Dependence on or widespread use of synthetic souls could have implications on jobs, human relationships, and society's structure.</li>
        <li><strong>Moral Rights:</strong> If the AI becomes extremely advanced, questions about its rights, or whether it should have any, could arise.</li>
        <li><strong>True Self:</strong> What does it mean to have a "synthetic soul"? Is it simply an emulation of consciousness or could it be considered genuine in some form?</li>
    </ul>

    <p>Remember, these are speculative suggestions based on current principles and might not cover all the nuances or unique attributes of future technology like "Synthetic Souls." The actual development and integration of such a concept would undoubtedly bring about many discussions and debates regarding its use and the ethical implications surrounding it.</p>
          {/* Additional terms text can go here */}
        </div>
        <br></br>
        <button onClick={onClose}>Agree & Continue</button>
      </div>
    </div>
  );
}
function App() {
  const [isTalking, setIsTalking] = useState(false); // Add state variable for talking animation
  const [transcript, setTranscript] = useState('');
  const recognitionRef = useRef(null); // useRef used to store a mutable value that does not cause a re-render when updated.
  const [isRecording, setIsRecording] = useState(false); // isRecording is the current state, setIsRecording is the function that is used to update our state.
  const [modalOpen, setModalOpen] = useState(true);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [stopAnimation, setStopAnimation] = useState(false);

  const handleAgreeAndCloseModal = () => {
    setModalOpen(false);
    setHasAgreed(true); // Set hasAgreed to true when user clicks "I Agree"
  };

  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false); // This should stop the talk animation
    setPlaying(false);
  }

  const toggleTalkingAnimation = () => {
    setIsTalking(!isTalking);
  };

  function stopAudioPlayback() {
    console.log("stopAudioPlayback called");
    if (audioPlayer.current && audioPlayer.current.audioEl.current) {
      console.log("Pausing audio");
      audioPlayer.current.audioEl.current.pause();
      setPlaying(false);
      setSpeak(false);
      setStopAnimation(true); // Add this line
    } else {
      console.log("Audio player not initialized");
    }
  }

  // handleStart(): sets isRecording to true and starts speech recognition using the webkitSpeechRecognition object.
  const handleStart = () => {
    setIsRecording(true);
    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.onresult = handleResult;
    recognitionRef.current.start();
  };

  // handleStop(): sets isRecording to false and stops speech recognition.
  const handleStop = () => {
    setIsRecording(false);
    recognitionRef.current.stop();
    
  };

  // handleResult(event): processes the result of speech recognition and updates the transcript state with the current transcript.
  const handleResult = (event) => {
    const transcriptArray = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript);
    const currentTranscript = transcriptArray.join('');
    setTranscript(currentTranscript);
  };

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState("My name is Samantha I was created by Aleem Williams");
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);

  // End of play
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
  }

  // Player is ready
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  return (
    
    <>
      <>
      </>
      <>
        <TermsModal isOpen={modalOpen} onClose={handleAgreeAndCloseModal} />

        {hasAgreed && ( // Only render main content if hasAgreed is true
          <div className="full">
            
            <div style={STYLES.area}>
              <textarea
                placeholder="Click and hold to record."
                rows={4}
                type="text"
                style={STYLES.text}
                value={transcript}
                onChange={(e) => setTranscript(e.target.value.substring(0, 200))}
              />
              <br />
              <div className="parent">
                <div className="child">
                  <button
                    onClick={() => setSpeak(true)}
                    className={`record-button ${isRecording ? 'recording' : ''}`}
                    // onMouseDown and onMouseUp event handlers are used to start and stop recording
                    onMouseDown={handleStart}
                    onMouseUp={handleStop}
                    // onDoubleClick event handler toggles between starting and stopping recording
                    onDoubleClick={isRecording ? handleStop : handleStart}
                  >
                    {/* If isRecording is true, the string 'Recording' will be displayed, and if it is false, the string 'Record' will be displayed. */}
                    {isRecording ? '' : ''}
                  </button>
                </div>
                <div className="child">
                  <button
                    className="listen"
                    onClick={() => setSpeak(true)}
                    style={STYLES.speak}
                  >
                    {speak ? 'Thinking...' : '.........'}
                  </button>
                </div>
                <div className="child">
                  <button
                    className="stop"
                    onClick={stopAudioPlayback}
                    style={STYLES.speak}
                  ></button>
                </div>
              </div>
            </div>

            <ReactAudioPlayer
              src={audioSource}
              ref={audioPlayer}
              onEnded={playerEnded}
              onCanPlayThrough={playerReady}
            />

            {/* <Stats /> */}
            <Canvas camera={{ position: [0, 4, 3], fov:35, dpr: 1 }} onCreated={(ctx) => {
              ctx.gl.physicallyCorrectLights = true;
            }}>
              <OrbitControls target={[0, 4, 0]} />
             <Suspense fallback={null}>
  <Environment
    ground={{ height: 5, radius: 20, scale: 40 }}
    background={true}
    files="/images/photo_studio_loft_hall_8k.hdr"
    stretch={false}
  />
</Suspense>
              <Suspense fallback={null}>
                <Avatar
                  avatar_url=""
                  speak={speak}
                  setSpeak={setSpeak}
                  text={text}
                  transcript={transcript}
                  setAudioSource={setAudioSource}
                  playing={playing}
                />
              </Suspense>
            </Canvas>
            <Loader dataInterpolation={(p) => `Loading... please wait`} />
          </div>
        )}
      </>
    </>
  );
}

export default App;
